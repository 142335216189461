*::selection {
  background-color: $blue;
  color: white;
}

body{
  font-size: 15px;
  p{
    justify-content: justify;
  }
}
// # Background image with object-fit
.bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;'; // esto va ligado con el plugin de https://github.com/fregante/object-fit-images
  /* Si se quiere la posición:
  object-position: bottom;
  font-family: 'object-fit: cover; object-position: bottom;';
  */
}

.c-purple { color: $black }
.c-default { color: #333 }

$sizes: 7, 12, 13, 14, 15, 17, 18, 22, 24, 48, 50, 80, 160;

@each $size in $sizes {
  .fs-#{$size} {
    font-size: rem($size);
  }
}

@include media-breakpoint-up(md) {
  .fs-md-250 { font-size: rem(250) }
}

@include media-breakpoint-up(lg) {
  .fs-lg-16 { font-size: rem(16) }
}

.link-green {
  color: #007e97;
}

.line-h-25 { line-height: rem(25) }

.text-d-none {
  @include hover {
    text-decoration: none;
  }
}

.overflow-hidden { overflow: hidden }

.container{
  max-width: 1350px;
}

@include media-breakpoint-up(lg) {
  h2{
    font-size: 2.5rem;
  }
}
.font-bold{
  font-weight: 700;
}
.masthead{
  padding: 0;
}
.subheader-container{
  position: relative;
  overflow: hidden;
}
.overlay{
  position: relative;
  background-color: rgba(0,0,0,.6);
  width: 100%;
  height: 100%;
  z-index: 2;
}
.flickity-button:disabled{
  opacity: 0; 
}