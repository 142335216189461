.news-related {
  border-top: rem(1) solid rgba(0,0,0,.08);
}

.new-date {
  color: #a8a8a8;
}

.list-img {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: rem(-60);
  margin-top: rem(-30);
  transform: scale(0);
  transition: transform .3s ease-in-out;
  z-index: 1;
  a {
    background-color: rgba(49, 39, 130, 0.8);
    height: rem(60);
    color: #fff;
    width: rem(60);
    transition: all .3s ease-in-out;
    @include hover {
      background-color: #fff;
      color: $blue;
    }
  }
}

.news-content {
  .card-c{
    h2{
      font-size: 1.4rem;
    }
  }
}