.blocks{
  h3{
    font-weight: bold;
  }
  .col-md-6{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .texto{
    max-width: 600px;
    padding: 1rem;
  }
}
.max-1000{
  max-width: 1000px!important;
  margin: 0 auto;
}