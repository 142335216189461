.cms {
  p, ul, ol {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

@include media-breakpoint-up(md) {

  .col-count-md-2 {
    column-count: 2;
    column-gap: $grid-gutter-width;
  }

}