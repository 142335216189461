.validation-form{
  .invalid-feedback{
    display: block;
  }
}
.form-bottom{
  .texto{
    @include media-breakpoint-up(lg) {
      padding-right: 4rem;
    }
  }
  input, textarea{
    border: 1px solid $black;
    &::placeholder{
      color: $black;
    }
  }
  form p{
    font-size: 13px;
  }
}

.form-bottom{
  .form-group{
    position: relative;
  }
  .input, textarea {
    border: 0;
    border-bottom: 2px solid #9e9e9e;
    outline: none;
    transition: .2s ease-in-out;
    box-sizing: border-box;
  }

  .input,
  textarea,
  label{
    width: 100%;
    height: 3rem;
    color: $black;
  }
  
  /* Interation */
  .input:valid,
  .input:focus,
  textarea:valid,
  textarea:focus {
    border-bottom: 2px solid $black;  
  }
  
  .input:valid + label,
  .input:focus + label, 
  textarea:valid + label,
  textarea:focus + label {
    color: #26a69a;
    font-size: .8rem;
    top: -30px;
    pointer-events: none;
  }
}