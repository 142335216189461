.soluciones{
  background-color: #f2f2f2;
  .box{
    background-color: $white;
    border-radius: 0;
    padding: 0;
    .col-md-6{
      padding: 0;
    }
    img{
      margin: 0;
    }
    .texto{
      padding: 5rem 3rem;
      margin: 0;
    }
    .btn{
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}
.industrias{
  background-color: $black;
  color: $white;
  padding-top: 4rem;
  h2{
    font-size: 23px;
    margin-bottom: 4rem;
  }
  .container-fluid{
    .row{
      display: flex;
      justify-content: center;
    }
  }
  .box{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 6rem 1rem;
    overflow: hidden;
    border-right: 1px solid $black;
    overflow-wrap: anywhere;
    @include media-breakpoint-down(md) {
      width: 40%;
    }
    @include media-breakpoint-down(sm) {
      width: 50%;
    }
    h3{
      position: relative;
      font-size: 15px;
      z-index: 3;
    }
    img{
      width: 80px;
      margin-bottom: 1rem;
    }
  }
}