.share {
  a {
    color: #a8a8a8;
    &.facebook {
      @include hover {
        color: #3B5998;
      }
    }
    &.twitter {
      @include hover {
        color: #1DA1F2;
      }
    }
    &.linkedin {
      @include hover {
        color: #0077B5;
      }
    }
    &.pinterest {
      @include hover {
        color: #BD081B;
      }
    }            
  }
}