/*
  # Reset
  # Outline
*/

.btn{
  border-radius: 0;
  padding: 0.8rem 2rem;
}

// # Reset
.btn-reset {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  cursor: pointer;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  //font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  &:focus {
    outline: none;
  }
}

.btn-default {
  height: rem(48);
  width: rem(48);
}

// # Outline
.no-outline {
  &:focus {
    outline: none;
  }
}

.btn-nav {

  position: absolute;
  right: 0;

  span {

    display: block;
    background-color: #0095eb;
    height: rem(1);
    width: rem(22);
    position: relative;

    &:before, &:after {
      content: '';
      background-color: #0095eb;
      display: block;
      width: rem(22);
      height: rem(1);
      position: absolute;
    }

    &:before {
      top: rem(-8)
    }

    &:after {
      bottom: rem(-8);
    }

  }
}

.btn-primary{
  background-color: #00c3b3;
  border: 0;
  font-weight: 600;
}
.btn-black{
  background-color: $black;
  border: 0;
  color: $white;
  font-weight: 600;
  &:hover{
    background-color: $white;
    color: $black;
  }
}
.btn-no-bg{
  background-color: $white;
  color: $black;
  font-weight: 600;
  padding: 1rem 0;
}
.btn-close {
  height: rem(48);
  position: absolute;
  right: 0;
  top: rem(6);
  width: rem(48);

  &:before, &:after {
    content: '';
    background-color: #fff;
    display: block;
    height: rem(20);
    width: rem(1);
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

}

.btn-lang {

  line-height: 1.5;
  &__icon {
    &:before {
      content: '-';
    }
  }
  &.collapsed {
    .btn-lang__icon {
      &:before {
        content: '+';
      }
    }
  }

}

.btn-custom {
  background-color: #fff;
  font-weight: 600;
  padding: rem(14) rem(52);
}

.btn-anim {
  border-radius: rem(5);
  position: relative;
  font-weight: 600;
  &:before {
    content: '';
    background-color: rgba(0,0,0,.05);
    bottom: 0;
    left: 0;
    position: absolute;
    transition: width .3s;
    top: 0;
    width: 0;
  }
  @include hover {
    &:before {
      width: 100%;
    }
  }
}

.btn-like {
  line-height: 1;
  margin-top: rem(1);
  @include hover {
    .heart__inside {
      fill: $blue;
    }
  }
  &.active {
    .heart__inside {
      fill: $blue;
      cursor: default;
    }
  }
}

.heart {
  fill: $blue;
  .heart__inside {
    fill: transparent;
    transition: fill .3s ease-in-out;
  }
}

.like {
  color: #a8a8a8;
  margin-left: rem(4);
}

.btn-more {
  background-color: #f7f7f7;
  color: #444;
  &__icon {
    background-color: rgba(0, 0, 0, .05);
    padding: 0 rem(16);
  }
  &__text {
    padding: rem(12) rem(20);
  }
}

.btn-filter {
  color: $black;
  &:not(.collapsed) {
    .icon-down {
      transform: rotate(180deg);
    }
  }
}
.filter-icon {
  margin-right: rem(6);
}

.btn-back {
  bottom: rem(20);
  color: #65666C;
  position: fixed;
  right: rem(60);
  z-index: $zindex-dropdown;
}