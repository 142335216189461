// SLIDE
.home-carousel {
  .carousel-cell{
    height: 800px;
    width: 100%;
    position: relative;
    .text {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      background: rgba(0,0,0,.3);
      padding: 15px;
    }
  }
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient( to bottom, transparentize(#0E1D33, 0.4) , transparentize(#0E1D33, .6) );
}
.productos-servicios{
  .row{
    align-items: center;
  }
  .texto{
    @media (min-width: 1100px) {
      padding-right: 8rem;
    }
    ul {
      padding-left: 1rem;
    }
  }
}
.soluciones-home{
  h4, h2,p{
    color: $white;
  }
  h4{
    font-size: 18px;
  }
  h2{
    font-size: 50px;
  }
  .h6{
    color: $blue;
    font-size: 1rem;
    font-weight: 600;
  }
  .texto{
    max-width: 750px;
    margin: 0 auto 4rem;
  }
  .box{
    background-color: white;
    border-radius: 10px;
    position: relative;
    top: 0.5rem;
    padding: 2rem;
    height: 100%;
  }
  span{
    color: #00c3b3;
    font-weight: 700;
    text-decoration: underline;
  }
}
.productos-home{
  h2{
    max-width: 1000px;
    margin: 0 auto;
    margin-bottom: 4rem;
  }
  .box{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    min-height: 400px;
    background-color: #dedede;
    overflow: hidden;
    @include media-breakpoint-down(md) {
      width: 50%;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    h3{
      position: relative;
      font-size: 20px;
      font-weight: 600;
      padding: 1rem 2rem;
      z-index: 3;
    }
    .bg-image{
      width: auto;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    .text{
      position: relative;
      color: $white;
      font-size: 14px;
      padding: 0 2rem;
      z-index: 3;
    }
    &:hover{
      img{
        transform: scale(1.1);
        transition: all 1s;
      }
    }
  }
}
.casos{
  h2{
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 6rem;
  }
  .nav-link{
    position: relative;
    transition: all 1s;
    padding: 0;
    &:hover{
      transition: all 1s;
      img{
        transform: scale(1.1);
        transition: all 1s;
      }
      h3{
        color: #00c3b3;
        transition: all 1s;
      }
    }
  }
  .img{
    width: 100%;
    height: 280px;
    overflow: hidden;
  }
  h3{
    font-size: 1.4rem;
    text-transform: initial;
    color: $black;
  }
  .ul-item{
    display: flex;
  }
  .ul-item:nth-child(2n+1){
    flex-direction: row-reverse;
  }
  span{
    position: absolute;
    top: 0;
    right: 0;
    background-color: #00c3b3;
    padding: .8rem .7rem .4rem;
    font-weight: bold;
    color: $white;
  }
}
.destacados{
  color: $white;
  li{
    display: flex;
    align-items: center;
  }
  img {
    height: 60px;
  }
  .carousel-cell {
    width: 25%;
  }
  .carousel-cell-image {
    display: block;
    max-height: 100%;
    margin: 0 auto;
    max-width: 100%;
    opacity: 0;
    -webkit-transition: opacity 0.4s;
            transition: opacity 0.4s;
  }
  .flickity-viewport{
    overflow: visible;
  }
  .carousel-cell-image.flickity-lazyloaded,
  .carousel-cell-image.flickity-lazyerror {
    opacity: 1;
  }
  .flickity-page-dots, .flickity-prev-next-button{
    display: none;
  }
}