.view-legal {

  h2, h3 {
    color: $black;
  }

  h2 {
    font-weight: 400;
  }

  h3 {
    font-weight: 300;
  }

  a {
    &:not(.btn-primary) {
      color: $black;
    }
  }

}