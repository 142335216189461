#over-cookies-msg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, .75);
  z-index: $zindex-popover;
  #cookies-msg {
    position: fixed;
    top: 50%;
    left: 50%;
    background-color: #007d97;
    color: #fff;
    border-radius: 0;
    margin-bottom: 0;
    padding: rem(10);
    z-index: $zindex-tooltip;
    transform: translate(-50%,-50%);
    width: 1200px;
    max-width: 90%;
    @include media-breakpoint-down(xs)
    {
     .btn{
      padding: 0;
      background: transparent;
     }
    }
  }
  &.cookies-policy{
    position: absolute;
    bottom: 65%;
    #cookies-msg {
      position: absolute;
    }
    @include media-breakpoint-down(lg)
    {
      bottom: 60%;
    }
  }
}

.video-no-cookies {
  position: relative;
  width: 100%;
  padding-top: percentage(9 / 16);
  background-repeat: no-repeat;
  &::before{
    position: absolute;
    z-index: 10;
    background: rgba(0,0,0,.75);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: "";
  }
  & span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 90%;
    padding: .5rem;
    color: white;
    z-index: 11;
    text-align: center;
  }
}

#popup-cookies-config {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 810px;
  max-width: 91%;
  z-index: $zindex-tooltip + 10;
  background: #FFF;
  transform: translate(-50%,-50%);
  display: none;
}