.pagination {
  .page-link {
    border: 0;
    color: $link-color;
    font-weight: bold;
    font-size: 18px;
    &:hover {
      background: none;
      text-decoration: underline;
    }
  }
  .active {
    .page-link {
      text-decoration: underline;
      background: none;
      color: $link-color;
    }
  }
}