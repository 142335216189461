footer {
  background-color: $black;
  font-size: 14px;
  @include media-breakpoint-up(lg) {
    bottom: 0;
    left: 0;
    position: sticky;
    right: 0;
  }
  .container{
    max-width: 1700px;
  }
}

.footer-top {
  padding-bottom: rem(70);
  padding-top: rem(70);
  a {
    color: #007d97;
  }
  .link.active{
    color: $white;
  }
  .link:after{
    display: none;
  }
}

.footer-bottom {
  border-top: rem(1) solid rgba(255,255,255,.1);
  padding-bottom: rem(30);
  padding-top: rem(20);
  a {
    color: #fff;
  }
}

.footer-funded {
  border-top: rem(1) solid rgba(255,255,255,.1);
}

.footer-address {
  border-bottom: rem(1) dashed #fff;
}

.footer-legal {
  @include media-breakpoint-up(lg) {
    display: flex;
  }
  li {
    &:not(:last-child) {
      margin-bottom: rem(5);
    }
  }
  a {
    background-color: rgba(0,0,0,.1);
    border: rem(1) solid rgba(0,0,0,.04);
    color: #ccc;
    display: block;
    padding: rem(7) rem(10);
    @include hover {
      color: #00c3b3;
      text-decoration: none;
    }
  }
}

.footer-logos {
  li {
    &:not(:last-child) {
      margin-bottom: rem(30);
    }
  }  
}