.breadcrumb {
  a {
    color: #fff;
  }
  .icon {
    margin: 0 rem(10);
    opacity: .4;
    position: relative;
    top: rem(3);
  }
}