.bg-repeat {
  background-repeat: repeat;
  background-position: center center;
}

.bg-clear { background-color: #FCFCFC }
.bg-blue { background-color: #313380 }
.bg-img-purple { background-image: url(../images/bg/bg-001.jpg) }
.bg-img-blue { background: linear-gradient(to bottom right, #313380, #2eb1ac); }
.bg-img-white { background-image: url(../images/bg/bg-003.jpg) }
.bg-img-custom { background-image: url(../images/bg/custom-001.jpg) }
.bg-home { background-image: url(../images/bg/battery-technology-companies.jpg) }
.bg-who { background-image: url(../images/bg/energy-storage-consultants.jpg) }
.bg-solutions { background-image: url(../images/bg/battery-diagnostic.jpg) }
.bg-news { background-image: url(../images/bg/Battery-consulting.jpg) }
.bg-cases { background-image: url(../images/bg/diagnostico-bateria.jpg) }
.bg-gray-items { background: #D5D3E8;}
.bg-before {
  &:before {
    content: '';
    background-color: rgba(255, 255, 255, .66);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}