

.list-main {
  font-size: rem(14);
  li {
    border-top: rem(1) solid rgba(255, 255, 255, .03);
    &:last-child {
      border-bottom: rem(1) solid rgba(255, 255, 255, .03);
    }
  }
  @media (max-width: 1400px) {
    font-size: .8rem;
    padding: 1rem;
  }
}

.list-lang {
  background-color: rgba(255, 255, 255, .025);
  a {
    padding: rem(9.5) rem(20) rem(9.5) rem(35);
  }
  .active {
    color: #fff;
  }
  @include media-breakpoint-up(xl) {
    background-color:$primary;
    li {
      &:not(:last-child) {
        border-bottom: rem(1) solid rgba(0, 0, 0, .05);
      }
    }
    a {
      color: #fff;
      padding: rem(12.5) rem(20);
      @include hover {
        background-color: rgba(0, 0, 0, .06);
        color: #f4f4f4;
      }
    }
  }
  
}

.menu {

  &.active {
    a {
      color: #fff;

    }
  }

  .submenu {
    width: max-content;
    max-width: 90vw;
    background: $white;
    padding: 1rem;
    display: none;
    z-index: 3;
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        margin-bottom: 1rem;
        &:last-of-type {
          margin-bottom: 0;
        }
        a {
          color: #1d1d1d;
        }
      }
    }
  }

  .with-submenu {
    &:hover {
      .submenu {
        display: block;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    a, button {
      color: #A6A6A6;
      @include hover {
        color: #fff;
      }
    }
    &__c {
      background-color: #191919;
      bottom: 0;
      overflow: auto;
      padding-top: rem(60);
      position: fixed;
      right: rem(-250);
      top: 0;
      transition: right 0.3s ease-out;
      width: rem(250);
      z-index: $zindex-menu;
    }
  }

}

.link {
  color: #1d1d1d;
  display: block;
  padding: rem(9.5) rem(20);
  font-weight: 600;
  @include media-breakpoint-up(xl) {
    padding: rem(19.5) rem(20);
    position: relative;
    &:after {
      content: '';
      display: block;
      background-color: #1d1d1d;
      height: rem(4);
      left: 10%;
      bottom: rem(6);
      width: 80%;
      position: absolute;
      transition: opacity .3s ease-in-out;
      opacity: 0;
    }
    &:hover, &.active {
      color: #1d1d1d;
      &:after {
        opacity: 1;
      }
    }
  }

}

.link-top {

  color: #0095eb;

  @include media-breakpoint-up(md) {

    color: #007e97;

  }

}

.list-contact {
  margin-top: rem(7);
  margin-bottom: rem(7);
}

@include media-breakpoint-down(lg) {

  .backdrop {
    background-color: rgba(0,0,0,.6);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-out;
    z-index: $zindex-backdrop;
  }

  .nav-opened {
    overflow: hidden;
    .menu {
      &__c {
        right: 0;
      }
    }
    .backdrop {
      opacity: 1;
      z-index: 1;
      pointer-events: all;
    }
  }

}


@include media-breakpoint-up(xl) {

  .li-lang {
    position: relative;
    color: #00c3b3;
    .collapse {
      position: absolute;
      right: 0;
    }
    @include hover {
      .collapse {
        display: block;
      }
    }
  }

}
.li-lang {
  color: #00c3b3;
}

.logo-main { display: block }
.logo-secondary { display: none }

.header-container {
  @include media-breakpoint-up(md) {
    position: relative;
    top: 0;
    background-color: $white;
    z-index: 5;
    padding: 1rem;
    img{
      height: 80px!important;
      width: auto!important;
    }
  }
}


  .header-fixed {
    background-color: #fff;
    box-shadow: 0 rem(2) rem(5) 0 rgba(0,0,0,0.1);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $zindex-fixed;    
    .header-top {
      display: none;
    }
    .logo-main { display: none }
    .logo-secondary { display: block }
  }

.masthead {
  background-size: cover;
  background-position: center center;
  @include media-breakpoint-up(md) {
    padding-top: rem(345);
  }
  @include media-breakpoint-up(lg) {
    padding-top: rem(350);
  }
}
