$like-width: rem(43);

.card-c {
  padding: rem(20);
}

.card-bottom {
  background-color: rgba(0, 0, 0, 0.02);
  padding: rem(7) rem(20);
}

.card-new-bottom {
  padding-bottom: rem(13.5);
  padding-top: rem(13.5);
}

.img-c {
  border: rem(8) solid #f8f8f8;
  &:after {
    content: '';
    background-color: rgba(0,0,0,.15);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: opacity .3s ease-in-out
  }
  img {
    transition: transform .3s ease-in-out;
    transform: scale(1);
  }
  @include hover {
    &:after {
      opacity: 1;
    }
    img {
      transform: scale(1.2);
    }
    .list-img {
      transform: scale(1);
    }
  }
}

.case-title {
  width: calc(100% - #{$like-width});
}
.case-like {
  width: auto;
}

