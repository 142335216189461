.nav-stories {
  .active {
    span {
      padding-bottom: 1px;
      border-bottom: 2px solid $link-color;
    }
  }
}

.subheader {
  background-color: $blue;
  padding: 7rem 3rem;
}

.post-nav {
  a {
    color: #333;
    opacity: .6;
    transition: opacity .3s ease-in-out;
    @include hover {
      opacity: 1;
    }
  }
}
.case-category{
  a{
    .img{
      max-height: 380px;
      overflow: hidden;
    }
    h2{
      color: $black;
    }
    .btn {
      border-radius: 0;
      padding: 0.5rem 1.3rem;
      font-size: 14px;
    }
    &:hover{
      text-decoration: none;
      img{
        transform: scale(1.1);
        transition: all 1s;
      }
      h2{
        color: #00c3b3;
        transition: all 1s;
      }
    }
    span{
      position: absolute;
      top: 0;
      right: 0;
      background-color: #00c3b3;
      padding: .8rem .7rem .4rem;
      font-weight: bold;
      color: $white;
    }
  }
}
.descripcion{
  margin: 7rem auto;
}
.case-content {
  .cms{
    margin-bottom: 2rem;
  }
  .box{
    height: 100%;
    padding: 3rem;
  }
  .cms:first-child .box{
    background-color: #d4e3e8;
  }
  .cms:nth-child(2) .box{
    background-color: #b9d4db;
  }
  .cms:nth-child(3) .box{
    background-color: #c8d8db;
  }
  .cms:nth-child(4) .box{
    background-color: #d0e4ea;
  }
  h2 {
    font-size: rem(18);
    font-weight: 500;
    margin-bottom: rem(15);
  }
  p{
    padding-left: 1rem;
  }
  @include media-breakpoint-up(lg) {
    h2 {
      font-size: rem(27);
      margin-bottom: rem(20);
    }
  }
}

.case-bottom {
  border-top: rem(1) solid rgba(0,0,0,.08);
}

.case-link {
  position: fixed;
  bottom: rem(40);
  img { transition: transform .3s ease-in-out }
  &__area {
    z-index: 1;
  }
  &__arrow {
    background-color: $blue;
    width: rem(35);
    z-index: 1;
  }
  &__content {
    bottom: 0;
    padding: rem(6) rem(15);
    transition: transform .3s ease-in-out;
    width: rem(190);
  }
}
.case-link__arrow{
  background-color: transparent;
}
.case-link--left {
  left: 0;
  .case-link__content { transform: translateX(-100%) }
  img { transform: translateX(-100%) }
  @include hover {
    img { transform: translateX(0) }
    .case-link__content { transform: translateX(rem(115)) }
  }
  @include media-breakpoint-up(xl) {
    img { transform: translateX(0) }
  }
}
.case-link--right {
  right: 0;
  img { transform: translateX(100%) }
  .case-link__area { flex-direction: row-reverse }
  .case-link__content {
    transform: translateX(rem(115));
  }
  @include hover {
    img { transform: translateX(0) }
    .case-link__content { transform: translateX(-100%) }
  }
  @include media-breakpoint-up(xl) {
    img { transform: translateX(0) }
  }
}

.case-date {
  border-top: rem(1) solid rgba(0,0,0,.08);
  color: #a8a8a8;
  margin-top: rem(3);
  padding-top: rem(3);
  .icon {
    margin-right: rem(2);
  }
}

.filter {
  color: #a8a8a8;
}

.list-filter {
  a {
    color: #a8a8a8;
  }
}

.filter-collapse {
  left: rem(15);
  position: absolute;
  right: rem(15);
  z-index: $zindex-dropdown;
  .card-body {
    border-bottom: rem(1) solid rgba(0,0,0,.08);
  }
}