.subheader-container{
  padding: 12rem 2rem;
  position: relative;
  overflow: hidden;
  img{
    position:absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
  .h1{
    font-size: 3rem;
    color: $white;
  }
  .container{
    position: relative;
    z-index: 3;
  }
}
.heading-title {
  color: $white;
  @include media-breakpoint-up(md) {
    font-size: rem(45);
  }
  @include media-breakpoint-up(lg) {
    font-size: rem(70);
  }
}

.heading-subtitle {
  color: #00c3b3;
  font-size: rem(18);
  
  @include media-breakpoint-up(md) {
    font-size: rem(25);
  }
}

.heading-text {
  color: $white;
  font-size: rem(15);
  @include media-breakpoint-up(md) {
    font-size: rem(18);
  }  
}


