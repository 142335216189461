.banner{
  h3{
    color: $white;
    font-weight: bold;
    font-size: 18px;
    padding: 0 1rem;
  }
  img {
    max-width: 100px;
    height: auto;
  }
}
.equipo{
  .box{
    text-align: center;
    border: 2px solid #dcdcdc;
    padding: 1rem;
    p{
      margin: 0;
    }
  }
}
.trabaja{
  a{
    color: $black;
    &:hover{
      color: #00c3b3;
      text-decoration: none;
    }
  }
  li{
    border: 1px solid #dedede;
    padding: 2rem;
  }
}